import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import PageHeader from "../../components/pageHeader"
import SEO from "../../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching from '../../components/images/imageCoaching.js'
import ImageDevelopement from '../../components/images/imageDevelopement.js'
import ImageClimat from '../../components/images/imageClimat.js'
import ImageChange from '../../components/images/imageChange.js'
import ImageConflit from '../../components/images/imageConflit.js'
import ImageLeft10 from '../../components/images/imageLeft10.js'

const Equipes = () => (
  <Layout>
    <SEO title="Réactualiser votre politique de prévention du harcèlement psychologique | Gestion Mieux-Être" />
    <PageHeader
        header="Réactualiser votre politique de prévention du harcèlement psychologique"
        text="La mise en place d’une politique corporative simple et allégée portant sur la prévention du harcèlement psychologique au travail est assurément une stratégie gagnante !  Cette politique doit s’inscrire dans une vision d’ensemble et être bien adaptée à votre contexte particulier."
        quote="Un dirigeant s’efforce perpétuellement d’apaiser les tensions, surtout quand il a affaire à des problèmes sensibles et complexes<span>- Nelson Mandela – Conversation avec moi-même</span>"
        background="tensions" />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">
        <div className="flex-container pad2">
            <div className="half"><ImageLeft10 /></div>
            <div className="half">
                <p style={{'text-align':'left','padding':'0 10%','line-height':'28pt'}}>Nous avons la conviction que la résolution des conflits peut y être abordée dans une approche « gagnant-gagnant ». C'est possible d'empêcher l’aggravation des problèmes qui mènent trop souvent à des escalades conflictuelles.</p>
            </div>

        </div>
        <p class="service-presentation2">Avec la participation active de vos gestionnaires et collaborateurs, la révision de votre politique en matière de prévention du harcèlement psychologique vous fournit un cadre d’intervention rigoureux. Cette politique clarifie aussi les rôles et responsabilités de tout un chacun.</p>
        <Link className="button-link" to="/nous-joindre">Nous joindre</Link>
        </ScrollAnimation>

    </div>
  </Layout>
)

export default Equipes
